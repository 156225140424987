<template>
  <div class="gato-registration wrapper">
    <div class="jumbotron vertical-center">
      <div class="row justify-content-center">
        <form class="col-sm-8 col-md-7 col-lg-5 col-xl-3" novalidate @submit.prevent="submit">
          <h3 class="h3 mb-4 font-weight-normal">{{ $t("accountRegistration.pageTitle") }}</h3>
          <fieldset :disabled="isLoading">
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-user"></i></span></b-input-group-prepend>
              <label for="name" class="sr-only">{{ $t("accountRegistration.form.name") }}</label>
              <b-input id="name" type="text" autocomplete="name" autofocus
                       :placeholder="$t('accountRegistration.form.name')"
                       v-model.trim="registration.name"
                       :state="status($v.registration.name)"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.registration.name.required">
                {{ $t("accountRegistration.validation.name.required") }}
              </div>
            </b-input-group>
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-mail"></i></span></b-input-group-prepend>
              <label for="email" class="sr-only">{{ $t("accountRegistration.form.email") }}</label>
              <b-input id="email" type="email" v-model="registration.email" autocomplete="email"
                       :placeholder="$t('accountRegistration.form.email')"
                       v-model.trim="registration.email"
                       :state="status($v.registration.email)"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.registration.email.required">
                {{ $t("accountRegistration.validation.email.required") }}
              </div>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.registration.email.email">
                {{ $t("accountRegistration.validation.email.valid") }}
              </div>
            </b-input-group>
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-lock"></i></span></b-input-group-prepend>
              <label for="password" class="sr-only">{{ $t("accountRegistration.form.password") }}</label>
              <b-input id="password" type="password" autocomplete="new-password"
                       :placeholder="$t('accountRegistration.form.password')"
                       v-model="registration.password"
                       :state="status($v.registration.password)"
                       @input="$v.registration.password.$touch()"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.registration.password.$error && !$v.registration.password.required">
                {{ $t("accountRegistration.validation.password.required") }}
              </div>
              <div class="invalid-tooltip d-block" v-if="$v.registration.password.$error && !$v.registration.password.minLength">
                {{ $t("accountRegistration.validation.password.minLength") }}
              </div>
            </b-input-group>
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-lock"></i></span></b-input-group-prepend>
              <label for="passwordAgain" class="sr-only">{{ $t("accountRegistration.form.password") }}</label>
              <b-input id="passwordAgain" type="password" autocomplete="new-password"
                       :placeholder="$t('accountRegistration.form.passwordAgain')"
                       v-model="registration.passwordAgain"
                       :state="status($v.registration.passwordAgain)"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.registration.passwordAgain.required">
                {{ $t("accountRegistration.validation.passwordAgain.required") }}
              </div>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.registration.passwordAgain.sameAs">
                {{ $t("accountRegistration.validation.passwordAgain.sameAs") }}
              </div>
            </b-input-group>
            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-primary px-5">
                  <b-spinner small v-if="isLoading"></b-spinner>
                  {{ $t("accountRegistration.button.register") }}
                </button>
              </div>
            </div>
          </fieldset>
          <div class="row mt-4">
            <div class="col-12 text-left px-0">
              <router-link class="btn btn-link" :to="routes.LOGIN">{{ $t("accountRegistration.link.login") }}</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {Routes} from '../../router';
  import {email, minLength, required, sameAs} from 'vuelidate/lib/validators';

  export default {
    name: "AccountRegistration",

    data() {
      return {
        routes: Routes,
        registration: {
          name: '',
          email: '',
          password: '',
          passwordAgain: ''
        },
        backendResponse: [],
        errors: []
      }
    },

    validations: {
      registration: {
        name: {
          required
        },
        email: {
          required,
          email
        },
        password: {
          required,
          minLength: minLength(8)
        },
        passwordAgain: {
          required,
          sameAs: sameAs("password")
        }
      }
    },

    computed: {
      isLoading: function () {
        return this.$store.state.isLoading;
      }
    },

    methods: {
      submit() {
        let self = this;

        self.$store.commit("isLoading", true);

        self.$v.registration.$touch();
        if (!self.$v.$invalid) {
          self.errors.length = 0;
          self.$store.commit("isLoading", false);
        } else {
          self.$store.commit("isLoading", false);
        }
      },

      status(validation) {
        if (this.isLoading || !validation.$dirty) {
          return null;
        }

        return validation.$dirty && !validation.$error;
      }
    }
  }
</script>

<template>
  <div class="gato-login wrapper">
    <div class="jumbotron vertical-center">
      <div class="row justify-content-center">
        <form class="col-sm-8 col-md-7 col-lg-5 col-xl-3" novalidate @submit.prevent="submit">
          <h3 class="h3 mb-4 font-weight-normal">{{ $t("login.pageTitle", {app:$t("global.appName")}) }}</h3>
          <transition name="slide">
            <b-alert v-if="errors && errors.length > 0" :show="errors && errors.length > 0" variant="danger">
              {{ errors[0] }}
            </b-alert>
          </transition>
          <fieldset :disabled="isLoading">
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-mail"></i></span></b-input-group-prepend>
              <label for="email" class="sr-only">{{ $t("login.form.email") }}</label>
              <b-input id="email" type="email" autocomplete="email" autofocus
                       :placeholder="$t('login.form.email')"
                       v-model.trim="login.email"
                       :state="status($v.login.email)"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.login.email.required">{{ $t("login.validation.email.required") }}</div>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.login.email.email">{{ $t("login.validation.email.valid") }}</div>
            </b-input-group>
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-lock"></i></span></b-input-group-prepend>
              <label for="password" class="sr-only">{{ $t("login.form.password") }}</label>
              <b-input type="password" id="password" autocomplete="current-password"
                       :placeholder="$t('login.form.password')"
                       v-model="login.password"
                       :state="status($v.login.password)"/>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.login.password.required">{{ $t("login.validation.password.required") }}</div>
            </b-input-group>
            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-primary px-5">
                  <b-spinner small v-if="isLoading"></b-spinner>
                  {{ $t("login.button.signIn") }}
                </button>
              </div>
            </div>
          </fieldset>
          <div class="row mt-4">
            <div class="col-12 text-left px-0">
              <router-link class="btn btn-link" :to="routes.ACCOUNT_RECOVERY">{{ $t("login.link.forgotPassword") }}</router-link>
            </div>
            <div class="col-12 text-left px-0">
              <router-link class="btn btn-link" :to="routes.ACCOUNT_REGISTRATION">{{ $t("login.link.accountRegistration") }}</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {Routes} from '../../router';
  import axios from 'axios';
  import {email, required} from 'vuelidate/lib/validators';

  export default {
    name: 'Login',

    data() {
      return {
        routes: Routes,
        login: {
          email: '',
          password: ''
        },
        backendResponse: [],
        errors: []
      }
    },

    validations: {
      login: {
        email: {
          required,
          email
        },
        password: {
          required
        }
      }
    },

    computed: {
      isLoading: function() {
        return this.$store.state.isLoading;
      }
    },

    methods: {
      submit() {
        let self = this;

        self.$store.commit("isLoading", true);

        self.$v.login.$touch();
        if (!self.$v.$invalid) {
          self.errors.length = 0;

          self.$store
            .dispatch("login", self.login)
            .then(() => self.$router.push('/'))
            .catch(e => {
              self.errors.push(e);
              self.$v.login.$reset();
            })
            .finally(() => self.$store.commit("isLoading", false));
        } else {
          self.$store.commit("isLoading", false);
        }
      },

      status(validation) {
        if (this.isLoading || !validation.$dirty) {
          return null;
        }

        return validation.$dirty && !validation.$error;
      },

      callHelloApi() {
        axios.get('api/hello')
          .then(response => {
            this.backendResponse = response.data
          })
          .catch(e => {
            this.errors.push(e)
          });
      }
    }
  }
</script>

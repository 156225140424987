<template>
  <div class="locale-switcher">
    <!-- {{ initLocale() }} -->
    <b-nav>
      <b-nav-item-dropdown :dropup="dropup" :text="dropdownLbl" right>
        <b-dropdown-item
          class="locale-link"
          v-for="locale in locales"
          :key="locale.id"
          @click="setLocale(locale)"
          :active="locale === activeLocale"
          href="#">
          {{ getLanguageString(locale) }}
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-nav>
  </div>
</template>

<script>

  import Vue from 'vue'
  // Restore locale from cookie, if it was set
  // import VueCookie from 'vue-cookie'
  // Vue.use(VueCookie)

  const localeStrings = {
    en: "English",
    cs: "Česky",
    es: "‪Español"
  }

  // Vue.config.lang = VueCookie.get('locale') || 'en'
  Vue.config.lang = 'cs'
  console.log("Locale from cookie = " + Vue.config.lang + ": language = " + localeStrings[Vue.config.lang])

  export default {
    props: {
      locales: {
        type: Array,
        default: ['cs']
      },
      showFull: Boolean,
      dropup: Boolean,
      locLabel: {
        type: String,
        default: ''
      }
    },
    data: function () {
      return {
        activeLocale: Vue.config.lang
      }
    },
    computed: {
      dropdownLbl: function () {
        return this.locLabel ? this.locLabel : localeStrings[this.activeLocale]
      }
    },
    methods: {
      setLocale: function (locale) {
        Vue.config.lang = locale
        this.activeLocale = locale
        // this.$cookie.set('locale', locale)
        this.$i18n.locale = Vue.config.lang
        console.log("New locale = " + Vue.config.lang + ": language = " + localeStrings[Vue.config.lang])
      },
      getLanguageString: function (locale) {
        return this.showFull ? localeStrings[locale] : locale
      }
    }
  }
</script>

<template>
  <div class="gato-recovery wrapper">
    <div class="jumbotron vertical-center">
      <div class="row justify-content-center">
        <form class="col-sm-8 col-md-7 col-lg-5 col-xl-3" novalidate @submit.prevent="submit">
          <h3 class="h3 mb-4 font-weight-normal">{{ $t("accountRecovery.pageTitle") }}</h3>
          <fieldset :disabled="isLoading">
            <b-input-group class="mb-4">
              <b-input-group-prepend><span class="input-group-text"><i class="icon-mail"></i></span></b-input-group-prepend>
              <label for="email" class="sr-only">{{ $t("accountRecovery.form.email") }}</label>
              <b-input id="email" type="email" autocomplete="email"
                       :placeholder="$t('accountRecovery.form.email')"
                       v-model.trim="recovery.email"
                       :state="status($v.recovery.email)"></b-input>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.recovery.email.required">
                {{ $t("accountRecovery.validation.email.required") }}
              </div>
              <div class="invalid-tooltip d-block" v-if="$v.$error && !$v.recovery.email.email">
                {{ $t("accountRecovery.validation.email.valid") }}
              </div>
            </b-input-group>
            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-primary px-5">
                  <b-spinner small v-if="isLoading"></b-spinner>
                  {{ $t("accountRecovery.button.submit") }}
                </button>
              </div>
            </div>
          </fieldset>
          <div class="row mt-4">
            <div class="col-12 text-left px-0">
              <router-link class="btn btn-link" :to="routes.LOGIN">{{ $t("accountRecovery.link.login") }}</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  import {Routes} from '../../router';
  import {email, required} from 'vuelidate/lib/validators';

  export default {
    name: "AccountRecovery",

    data() {
      return {
        routes: Routes,
        recovery: {
          email: ''
        },
        backendResponse: [],
        errors: []
      }
    },

    validations: {
      recovery: {
        email: {
          required,
          email
        }
      }
    },

    computed: {
      isLoading: function () {
        return this.$store.state.isLoading;
      }
    },

    methods: {
      submit() {
        let self = this;

        self.$store.commit("isLoading", true);

        self.$v.recovery.$touch();
        if (!self.$v.$invalid) {
          self.errors.length = 0;
          self.$store.commit("isLoading", false);
        } else {
          self.$store.commit("isLoading", false);
        }

      },

      status(validation) {
        if (this.isLoading || !validation.$dirty) {
          return null;
        }

        return validation.$dirty && !validation.$error;
      }
    }
  }
</script>

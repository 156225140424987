import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    isLoading: false
  },

  mutations: {
    isLoading(state, loading) {
      state.isLoading = loading;
    },

    login(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = token;
    },

    logout(state) {
      state.token = '';
      localStorage.removeItem('token');
      delete axios.defaults.headers.common['Authorization'];
    }
  },

  actions: {
    login({commit}, user) {
      return new Promise((resolve, reject) => {
        axios({url: '/api/auth/login', data: user, method: 'POST'})
          .then(response => {
            commit('login', response.data.token)
            resolve(response);
          })
          .catch(error => {
            // localStorage.removeItem('token');
            commit('logout');
            reject(error);
          });
      });
    },

    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('logout');
        resolve();
      });
    }
  },

  getters: {
    isLoggedIn: state => !!state.token
  }
})

import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Login from './views/auth/Login.vue'
import AccountRegistration from './views/auth/AccountRegistration.vue'
import AccountRecovery from './views/auth/AccountRecovery.vue'

Vue.use(Router);

export const Routes = Object.freeze({
  HOME: "/",
  ABOUT: "/about",
  LOGIN: "/login",
  ACCOUNT_REGISTRATION: "/registration",
  ACCOUNT_RECOVERY: "/recover"
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: Routes.HOME,
      name: 'home',
      component: Home
    },
    {
      path: Routes.LOGIN,
      name: 'login',
      component: Login
    },
    {
      path: Routes.ACCOUNT_REGISTRATION,
      name: 'accountRegistration',
      component: AccountRegistration
    },
    {
      path: Routes.ACCOUNT_RECOVERY,
      name: 'accountRecovery',
      component: AccountRecovery
    },
    {
      path: Routes.ABOUT,
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : {x: 0, y: 0};
  }
})

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './assets/scss/custom.scss'

import './assets/css/fonts.css'
import './assets/css/icons.css'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

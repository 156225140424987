<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="light" variant="light">
        <b-navbar-brand>{{ $t("global.appName") }}</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item to="/">{{ $t("nav.home") }}</b-nav-item>
            <b-nav-item to="/about">{{ $t("nav.about") }}</b-nav-item>
            <b-nav-item v-if="!isLoggedIn" to="/login">{{ $t("nav.login") }}</b-nav-item>
            <b-nav-item v-else @click="logout">{{ $t("nav.logout") }}</b-nav-item>
            <b-nav-item v-if="!isLoggedIn" to="/registration">{{ $t("nav.accountRegistration") }}</b-nav-item>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <LocaleSwitcher :locales="['cs', 'en', 'es']" :show-full="true"/>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <router-view/>
  </div>
</template>

<style>
  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
</style>

<script>
  import {Routes} from './router';
  import LocaleSwitcher from "./components/LocaleSwitcher";

  export default {
    components: {
      LocaleSwitcher
    },

    computed: {
      isLoggedIn: function () {
        return this.$store.getters.isLoggedIn;
      }
    },

    methods: {
      logout: function () {
        this.$store
          .dispatch('logout')
          .then(() => this.$router.push(Routes.LOGIN));
      }
    }
  }
</script>
